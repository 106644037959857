<template>
	<div class="w-full relative">
		<div class="flex justify-between">
			<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Состав ОС по НОК</h1>
			<div class="flex">
				<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="flex flex-col w-full h-auto mt-4 rounded-lg bg-white filter shadow-default relative">
			<!-- <div class="flex w-full h-auto mt-4 px-7 py-5 rounded-lg bg-white filter shadow-default"> -->
			<div class="relative w-full rounded-lg bg-white">
				<div class="t-overflow w-full">
					<div class="table w-full" :style="users.length > 0 ? 'min-height: 350px' : ''">
						<!-- Заголовки -->
						<div class="table-header-group">
							<div class="thead table-row content-center">
								<div v-for="(item, key) in fields" :key="'A' + key" class="table-cell th-content border-b sm:border-gray-100 sm:relative">
									<p
										class="w-full my-auto text-gray-400 cursor-pointer font-normal justify-between items-center"
										:class="{ 'text-blue-500 underline': filterBy === key }"
										@click="switchFilterVisibility(key)"
									>
										<span :class="{ 'border-b': params.filters.find((f) => f.fieldName === item.fieldName) }">
											{{ item.Name }}
										</span>
										<!-- eslint-disable max-len -->
										<svg class="sm:hidden" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M18.1436 28.1027C17.6841 28.1027 17.2245 27.9913 16.8068 27.7546C15.9574 27.2811 15.4421 26.4178 15.4421 25.443V21.154C15.4421 20.8895 15.2332 20.3742 15.0244 20.1097L12.0026 16.9347C11.4317 16.3638 11 15.3612 11 14.5953V12.7433C11 11.1976 12.1697 10 13.6597 10H24.3403C25.8024 10 27 11.1976 27 12.6597V14.4421C27 15.4169 26.443 16.4891 25.886 17.0461L22.3629 20.1654C22.1401 20.3603 21.9034 20.8477 21.9034 21.2515V24.7328C21.9034 25.6101 21.3742 26.5849 20.6641 27.0026L19.564 27.7128C19.1323 27.9774 18.6449 28.1027 18.1436 28.1027ZM13.6597 12.0888C13.3255 12.0888 13.0888 12.3673 13.0888 12.7433V14.5953C13.0888 14.7763 13.2837 15.2498 13.5065 15.4726L16.5979 18.7311C17.0714 19.3299 17.5309 20.2907 17.5309 21.154V25.443C17.5309 25.7215 17.7119 25.8747 17.8233 25.9304C17.9765 26.0139 18.2272 26.0696 18.45 25.9304L19.564 25.2063C19.6754 25.1227 19.8146 24.8442 19.8146 24.705V21.2237C19.8146 20.235 20.302 19.1349 20.9983 18.564L24.4517 15.5004C24.6327 15.3194 24.9112 14.7624 24.9112 14.4143V12.6597C24.9112 12.3534 24.6466 12.0888 24.3403 12.0888H13.6597Z"
												fill="#9193A5"
											/>
										</svg>
										<!-- eslint-enable max-len -->
									</p>
									<!-- Фильтр и сортировка -->
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										:position="key === 0 ? 'left' : 'right'"
										:filterOpen="filterOpen"
										:id-filter="key"
										:param="params"
										@query="getData"
										:fields="fields"
										:item="item"
									/>
								</div>
							</div>
						</div>
						<!-- /Заголовки -->

						<!-- Содержимое -->
						<div v-if="users" class="tbody table-row-group content-center">
							<div v-for="item in users" :key="item.id" class="table-row sm:border-b item-center sm:border-gray-100 text-gray-500">
								<div class="table-cell border-b border-gray-100">
									<p>{{ item.created | moment('DD.MM.YYYY') }}</p>
								</div>
								<div class="table-cell border-b border-gray-100">
									<p>{{ item.fname }}</p>
								</div>
								<div class="table-cell border-b border-gray-100">
									<p>{{ item.iname }}</p>
								</div>
								<div class="table-cell border-b border-gray-100">
									<p>{{ item.oname }}</p>
								</div>
								<div class="table-cell border-b border-gray-100">
									<p>{{ item.workPlace }}</p>
								</div>
								<div class="table-cell border-b border-gray-100">
									<p>{{ item.position }}</p>
								</div>
								<div class="table-cell border-b border-gray-100">
									<p>{{ item.organizationName }}</p>
								</div>
								<div class="table-cell border-b border-gray-100">
									<div class="ava">
										<img v-if="item.logoUrl" :src="`https://apiosnok.minzdrav.gov.ru${item.logoUrl}`" alt="" />
										<Icons v-else icon-name="user" />
									</div>
								</div>
							</div>
						</div>
					</div>

					<center v-if="!loadingTable && users.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
				</div>
				<div class="hidden sm:flex flex-row pt-6 px-20 pb-12 text-gray-400 text-sm">
					<div class="flex flex-row ml-auto">
						<p>Строк на странице:</p>
						<ul class="flex flex-row">
							<li @click="updateRow(5)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 5 }">5</li>
							<li @click="updateRow(10)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 10 }">10</li>
							<li @click="updateRow(15)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 15 }">15</li>
						</ul>
					</div>
					<div class="flex flex-row ml-24">
						<ul class="flex flex-row">
							<li class="flex ml-4 cursor-pointer arrow" @click="page !== 0 ? navigation('params', page - 1) : ''" />
							<li class="flex">
								<span>Стр</span>
								<b class="ml-2">{{ page + 1 }}</b>
								<span class="ml-2">из</span>
								<b class="ml-2">{{ totalCount }}</b>
							</li>
							<li class="flex ml-2 cursor-pointer arrow" @click="page + 1 < totalCount ? navigation('params', page + 1) : ''" />
						</ul>
					</div>
				</div>
				<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
					<loadingComponent class="absolute top-1/2 left-1/2" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
// Глобальные данные стора
import { getters, mutations, methods } from '@/store/store';
// Локальные данные стора
import actions from '../store/actions';
import Icons from '@/components/Icons.vue';
import FilterComponent from '@/components/Filter.vue';
import loadingComponent from '@/components/Loading.vue';

export default {
	name: 'TableCompound',
	components: {
		loadingComponent,
		Icons,
		FilterComponent,
		Button
	},
	data() {
		return {
			rowsVisible: 5,
			totalCount: 0,
			page: 0,
			filterOpen: null,
			filterData: {
				sortBy: '',
				searchValue: ''
			},
			filterBy: null,
			fields: [
				{
					Name: 'Дата регистрации',
					fieldName: 'created',
					filterType: 3,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Фамилия',
					fieldName: 'fname',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Имя',
					fieldName: 'iname',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Отчество',
					fieldName: 'oname',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Место работы',
					fieldName: 'workPlace',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Должность',
					fieldName: 'position',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Общественная организация',
					fieldName: 'organizationName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Фото',
					fieldName: 'departmentBelongId',
					filterType: 0,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			upadte: false,
			users: [],
			popupTitle: '',
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 5,
				currentPage: 0
			},
			loadToExcell: false,
			loadingTable: false
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...methods,
		switchFilterVisibility(key) {
			this.filterData = {
				sortBy: 'asc',
				searchValue: ''
			};
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
			this.params.pageSize = rowsVisible;
			this.getData(this.params, true);
		},
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
				this.page = 0;
			}

			this.getOSMembers(params).then((res) => {
				this.users = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.rowsVisible);
				this.loadingTable = false;
			});
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name], false);
		},
		downloadExcell(params) {
			this.loadToExcell = true;

			this.$http
				.post('OS/GetOSMembersExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.regionName}_Состав ОС.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		}
	},
	mounted() {
		if (this.userIsInRoles(['Support', 'MZ_Employee', 'MZ_Observer'])) {
			this.params.filters[0].value1 = this.$route.query.regionId;
		} else {
			this.params.filters[0].value1 = this.getAuthUser.regionId;
		}

		this.getData(this.params, true);
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.select-label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.radio-group {
	margin-bottom: 20px;
}

.multiselect {
	margin-bottom: 20px;
}
.multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
}
.multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 50px;
}
.multiselect__option--highlight:after {
	display: none;
}
.multiselect__select {
	height: 50px;
}
.label {
	align-items: center;
	cursor: pointer;
}
.label:hover .label-text {
	text-decoration: none;
}
.label-text {
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #3377ff;
}
.ava {
	background: #f2f5ff;
	border-radius: 50%;
	width: 80px;
	overflow: hidden;
	margin-right: 20px;
	height: 80px;
	display: flex;
	background-size: cover;
	background-position: 50% 50%;
}
.ava .icon,
.ava img {
	margin: auto;
}
.th-content .table-cell,
.table-cell {
	padding: 20px;
	vertical-align: top;
}
.th-content {
	white-space: nowrap;
}

.th-content span {
	font-weight: 600;
	text-decoration: underline;
}

.table-cell span {
	margin: auto 0;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-cell span.m-auto {
	margin: auto;
}

.table-cell a {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-decoration-line: underline;
	white-space: nowrap;
	color: #3377ff;
}

.table-cell a:hover {
	text-decoration: none;
}

.icon-control {
	cursor: pointer;
}

.icon-control:hover rect {
	fill: #3377ff;
}

.t-overflow {
	overflow: auto;
	min-height: 300px;
	max-width: 100vw;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
