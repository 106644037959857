<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-6 sm:py-12 gap-5">
		<!-- Таблица -->
		<TableCompound v-if="$route.name === 'Compound'" />
		<TableProtocols v-else />
	</div>
</template>

<script>
import TableCompound from './TableCompound.vue';
import TableProtocols from './TableProtocols.vue';

export default {
	name: 'Main',
	components: { TableCompound, TableProtocols }
};
</script>

<style scoped>
.white-round-box {
	@apply sm:px-10 sm:py-8 rounded-lg bg-white filter drop-shadow-default;
}
</style>
